.form-wrapper {
  max-width: 450px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
}
.form-body {
  padding: 32px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-body p {
  letter-spacing: 0.5px;
  margin-top: 0;
}
.form-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  margin: auto;
}
.form-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 180px;
  height: 55px;
  padding: 16px;
  margin-bottom: 16px;
  letter-spacing: 0.8px;
  background-color: #7ba9e8;
  color: white;
  border: none;
  border-radius: 0.65rem;
  cursor: pointer;
}
.form-button:last-child {
  margin-bottom: 0;
}
.form-button:hover {
  background-color: #8eb6eb;
}
.form-button span {
  width: 150px;
  font-weight: 500;
}
.form-button-border {
  height: 32px;
  border-left: 2px solid white;
}
.form-button-icon {
  width: 32px;
  height: 32px;
}
.form-header {
  background-color: #7ba9e8;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.form-icon {
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 130px;
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-title {
  font-size: 24px;
  color: white;
  font-weight: 500;
}
.wordWrap {
  word-wrap: break-word;
}

#loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #7ba9e8; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#providers {
  display: none;
}
#error {
  display: none;
}
