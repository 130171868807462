.form-wrapper {
  border-radius: 15px;
  max-width: 450px;
  overflow: hidden;
  box-shadow: 0 5px 40px #00000029;
}

.form-body {
  background: #fff;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  display: flex;
}

.form-body p {
  letter-spacing: .5px;
  margin-top: 0;
}

.form-buttons {
  flex-direction: column;
  justify-content: center;
  width: 250px;
  margin: auto;
  display: flex;
}

.form-button {
  letter-spacing: .8px;
  color: #fff;
  cursor: pointer;
  background-color: #7ba9e8;
  border: none;
  border-radius: .65rem;
  justify-content: space-between;
  align-items: center;
  min-width: 180px;
  height: 55px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
}

.form-button:last-child {
  margin-bottom: 0;
}

.form-button:hover {
  background-color: #8eb6eb;
}

.form-button span {
  width: 150px;
  font-weight: 500;
}

.form-button-border {
  border-left: 2px solid #fff;
  height: 32px;
}

.form-button-icon {
  width: 32px;
  height: 32px;
}

.form-header {
  background-color: #7ba9e8;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  display: flex;
}

.form-icon {
  background-color: #fff;
  border-radius: 130px;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  margin-bottom: 16px;
  padding: 8px;
  display: flex;
}

.form-title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.wordWrap {
  word-wrap: break-word;
}

#loader {
  border: 8px solid #f3f3f3;
  border-top-color: #7ba9e8;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: auto;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#providers, #error {
  display: none;
}

@font-face {
  font-family: proxima-nova;
  src: url("ProximaNova-Semibold.8448dd48.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova;
  src: url("ProximaNova-Regular.5fbe883a.otf");
  font-weight: normal;
  font-style: normal;
}

html, body {
  height: 100%;
  font-family: proxima-nova !important;
}

.page-wrapper {
  background: url("bg.e4c49619.png");
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

/*# sourceMappingURL=index.0adcae38.css.map */
