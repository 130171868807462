@import "./form.css";

@font-face {
  font-family: "proxima-nova";
  src: url("../../public/assets/fonts/ProximaNova-Semibold.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../public/assets/fonts/ProximaNova-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  font-family: "proxima-nova" !important;
}

.page-wrapper {
  background: url("../../public/assets/images/bg.png");
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
